<template>
	<PageLayout :title="$t('deposit.title')">
		<div v-if="!loading" class="cashier-box cashier-box--deposit">
			<form ref="paymentForm" method="post" action="https://www.onlinenaira.com/process.htm">
				<input type="hidden" name="member" :value="merchantId" />
				<input type="hidden" name="action" value="payment" />
				<input type="hidden" name="product" value="Deposit for Service" />
				<input type="hidden" name="price" :value="convertedAmount" />
				<input type="hidden" name="country" value="NGN" />
				<input type="hidden" name="apikey" :value="apikey" />
				<input type="hidden" name="pid" :value="transactionId" />
				<input type="hidden" name="ureturn" :value="nairaReturnSuccess" />
				<input type="hidden" name="ucancel" :value="nairaReturnFailure" />
				<input type="hidden" name="unotify" :value="nairaWebhook" />

				<payment-logo :custom-logo="'2naira.png'" />
				<div class="small-width overflow-visible">
					<payment-wallet />
				</div>

				<div class="line-separator-wrapper">
					<div class="line-separator" />
				</div>

				<amount-buttons
					:buttons-value="currentMethod.predefinedDepositAmounts"
					:form-amount-value="form.amount || currentMethod.predefinedDepositAmounts[0]"
					:currency="'USD'"
					:form-errors="formErrors['amount']"
					:current-method="currentMethod"
					@set-button-value="setButtonValue"
					@input-amount-value-validation="setFormValidation"
				/>
				<span class="amount-error">{{ formErrors.amount }}</span>
				<div class="small-width">
					<promocode
						:form-promo-value="form.promocode"
						:form-error="formErrors['promocode']"
						@set-promo-value="setPromoValue"
					/>
				</div>

				<button type="button" class="button primary-button" :disabled="!form.validated" @click="pay()">
					{{ $t('deposit.top-up') }}
				</button>

				<div v-if="loading" class="loader-wrapper">
					<loader />
				</div>
			</form>
		</div>

		<div v-if="!loading" class="method-description-cashier">
			<a href="https://onlinenaira.com/members/signup.htm">
				{{ $t(`deposit.method-descriptions.naira`) }}
			</a>
		</div>
	</PageLayout>
</template>
<script>
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';

const loader = () => import('../../../../shared/loader/Loader.vue');
const paymentLogo = () => import('../../../../shared/payment-logo/PaymentLogo');
const amountButtons = () => import('../../../../shared/amount-buttons/AmountButtons');
const promocode = () => import('../../../../shared/promocode/Promocode');
const paymentWallet = () => import('../../../../shared/payment-wallet/PaymentWallet');

export default {
	name: 'DepositOnlineNaira',
	components: {
		PageLayout,
		loader,
		paymentLogo,
		amountButtons,
		promocode,
		paymentWallet,
	},
	data() {
		return {
			nairaReturnSuccess: `${process.env.VUE_APP_CASHIER_FRONTEND_URL}/payments/deposit/confirmation?module=Deposit&status=success&method=onlinenaira`,
			nairaReturnFailure: `${process.env.VUE_APP_CASHIER_FRONTEND_URL}/payments/deposit/confirmation?module=Deposit&status=error&method=onlinenaira`,
			form: {
				amount: null,
				promocode: null,
				validated: true,
			},
			formErrors: {
				amount: null,
				promocode: null,
			},
			loading: false,
			paymentDone: false,
			transactionId: '',
			nairaWebhook: '',
			merchantId: '',
			apikey: '',
			convertedAmount: 0,
			walletId: null,
		};
	},
	computed: {
		currentMethod() {
			return this.$store.getters.getCurrentMethod('onlinenaira');
		},
	},
	async mounted() {
		await this.getUserWallet();
	},
	methods: {
		async getUserWallet() {
			try {
				const { id } = await apiClient.fetchFullWallet(this.$route.params.walletId);
				this.walletId = id;
			} catch (e) {
				this.error = 'wallets.failed-to-load-wallet';
			} finally {
				this.loading = false;
			}
		},
		setButtonValue(event) {
			this.form.amount = event;
		},
		setPromoValue(event) {
			this.form.promocode = event;
		},
		setFormValidation(event) {
			this.form.validated = event;
		},
		async createTransaction() {
			const response = await apiClient.depositOnlineNaira(this.walletId, this.form.amount, 'NGN', this.form.promocode);

			if (response.status === 201) {
				const { transactionId, webhook, merchantId, apikey, amount } = response.data;
				this.transactionId = transactionId;
				this.nairaWebhook = webhook;
				this.merchantId = merchantId;
				this.apikey = apikey;
				this.convertedAmount = amount;
			} else {
				throw new Error('Invalid response from create transaction');
			}
		},
		async pay() {
			try {
				await this.createTransaction();
				this.triggerForm();
			} catch (e) {
				if (e.response?.status === 422) {
					const { errors } = e.response.data;
					errors.forEach(({ param, msg }) => {
						this.formErrors[param] = msg;
					});
					return;
				}

				const walletId = this.$route.params.walletId;
				this.$router.push({
					path: `/payments/deposit/${walletId}/confirmation`,
					query: { status: 'error' },
				});
			}
		},
		triggerForm() {
			this.$refs.paymentForm.submit();
		},
	},
};
</script>
<style scoped>
.hidden {
	visibility: hidden;
}

.amount-buttons-wrapper {
	margin: 0 auto 5px;
	border-top: 0px;
}

.cashier-box {
	padding: 0px 0 50px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}
</style>
